/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import React, { useEffect, useState } from "react";
import ReactPixel from "react-facebook-pixel";
import ReactGA4 from "react-ga4";
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import { pushDataLayerAndEvent } from "./Helpers/tagManager";
import Loader from "./components/Loader";
import { handleSelectedStateStorage } from "./components/LocationModal";
import UserTerms from "./components/Terms-UserAccept";
import { useAuth } from "./hooks/useAuth";
import { useLocation } from "./hooks/useLocation";
import Routes from "./routes";
import {
  LOCATION_DATA_EXPIRATION_IN_DAYS,
  getCookieEncrypted,
} from "./services/constants";
import KondutoService from "./services/konduto";
import { YunoProvider } from "./context/YunoContext";
import { detectorOfAndroid, detectorOfOS, isInZigApp } from "./services/utils";
import "./styles.css";
import DownloadPopup from "./components/DownloadPopup";

dayjs.extend(customParseFormat);

function App() {
  const { handleIPLocation } = useLocation();
  const { user, initialize, isInitialized } = useAuth();
  const [yunoInstance, setYunoInstance] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const os = detectorOfOS();
  const isAndroid = detectorOfAndroid();
  const isWhitelabel = !!Number(process.env.REACT_APP_IS_WHITELABEL);

  const limitToObtainIP = localStorage.getItem("limitDate");
  const userLocationData = JSON.parse(localStorage.getItem("userData") || "{}");
  const locationPermission = document.cookie
    .split("; ")
    .find((cookie) => cookie.includes("locationPermission"));

  const shouldObtainLocation =
    (!Object.keys(userLocationData).length && !locationPermission) ||
    limitToObtainIP <= dayjs();

  const downloadCookie = getCookieEncrypted("downloadPopup");

  useEffect(() => {
    initialize();

    if (!limitToObtainIP) {
      const limitDate = dayjs().add(LOCATION_DATA_EXPIRATION_IN_DAYS, "day");
      localStorage.setItem("limitDate", limitDate);
      handleSelectedStateStorage();
    }

    if (shouldObtainLocation) {
      handleIPLocation();
    }

    if (process.env.REACT_APP_PIXEL_ID) {
      ReactPixel.init(process.env.REACT_APP_PIXEL_ID);
      //
    }

    if (process.env.REACT_APP_GA4_CODE) {
      ReactGA4.initialize(process.env.REACT_APP_GA4_CODE);
    }

    if (process.env.REACT_APP_GTM_CODE) {
      TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_CODE });
    }

    if (process.env.REACT_APP_YUNO_PUBLIC_API_KEY) {
      setYunoInstance(
        window.Yuno.initialize(process.env.REACT_APP_YUNO_PUBLIC_API_KEY)
      );
    }

    const loadScripts = async () => await KondutoService.loadScript();

    loadScripts();
  }, []);

  useEffect(() => {
    if (!downloadCookie && (isAndroid || os === "iOS")) {
      setIsOpen(true);
    }

    if (downloadCookie && downloadCookie.userAction === "yes_install_app") {
      setIsOpen(false);
    }

    if (downloadCookie && downloadCookie.userAction === "no_install_app") {
      const currentDate = dayjs();
      const differenceInDays = currentDate.diff(
        dayjs(downloadCookie.date),
        "day"
      );

      if (differenceInDays >= 7) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
  }, []);

  useEffect(() => {
    if (isInitialized && user) {
      KondutoService.setCustomerID(user.email);

      pushDataLayerAndEvent({
        user_id: user.id,
        is_buyer: user.is_buyer,
        active_buyer: user.active_buyer,
        last_order_ago: user.last_order_ago,
      });
    }
  }, [isInitialized, user]);

  if (!isInitialized) {
    return (
      <>
        <div className="loading-container"></div>
        <Loader></Loader>
      </>
    );
  }

  return (
    <BrowserRouter>
      {!isOpen && !isInZigApp() && <UserTerms />}
      {!isInZigApp() && !isWhitelabel && (
        <DownloadPopup
          os={os}
          app={isAndroid}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
      <div className="App">
        <YunoProvider yunoInstance={yunoInstance}>
          <Routes />
        </YunoProvider>
      </div>
    </BrowserRouter>
  );
}

export default App;
