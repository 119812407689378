import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Loader from "../../../components/Loader";
import NewApi from "../../../services/new-api";
import "./index.css";

import question from "../../../assets/img/skip.png";
import { useTranslation } from "react-i18next";

export default function Index({ history }) {
  const [faqs, setfaqs] = useState([{ id: "", pergunta: "", resposta: "" }]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  function getFaqs() {
    setLoading(true);

    const query = {};
    const category = localStorage.getItem("TIPE_FAQ");

    if (category) {
      query.by_category = category;
    }

    new NewApi()
      .get("faqs", { params: query })
      .then(
        (res) => setfaqs(res.data.data),
        () => {}
      )
      .finally(() => setLoading(false));
  }

  function redirectSingleFac(id) {
    history.push("/faqs/" + id);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    getFaqs();
  }, []);

  function redirect(params) {
    localStorage.setItem("typeQuestion", params);
    history.push("/fale-form");
  }

  return (
    <>
      {loading ? (
        <>
          <div className="loading-container"></div>
          <Loader></Loader>
        </>
      ) : (
        <div id="faqs" className="text-center">
          <div className="link-buttons">
            <Link className="link-bt" to="/termos">
              Termos de uso
            </Link>
            <div className="link-bt" onClick={() => redirect("user")}>
              <p>
                <b>Formulário de contato</b>
                <br />
                <small>(Retorno em até 48h)</small>
              </p>
            </div>
          </div>

          <div className="container">
            <h3>{t("FAQs.frequentQuestions")}</h3>
            {faqs.map((fac) => (
              <div
                className="col-12 blocks"
                key={fac.id}
                onClick={() => redirectSingleFac(fac.id)}
              >
                <p>{fac.question}</p>
                <img src={question} alt="" />
              </div>
            ))}
          </div>
        </div>
      )}
      <Helmet>
        <script>
          {`
    (function (o, c, t, a, d, e, s, k) {
      o.octadesk = o.octadesk || {};
      o.octadesk.chatOptions = {
        subDomain: a,
        showButton: d,
        openOnMessage: e,
        hide: s
      };
      var bd = c.getElementsByTagName("body")[0];
      var sc = c.createElement("script");
      sc.async = 1;
      sc.src = t;
      bd.appendChild(sc);
    })(window, document, 'https://cdn.octadesk.com/embed.js', 'o159317-b69',  'true', 'true', 'false');`}
        </script>
      </Helmet>
    </>
  );
}
